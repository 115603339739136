import React, { ChangeEvent, useContext } from 'react'
import { LangContext, languageOptions } from '../context/langContext';



const LangSwitcher = () => {

    const { currentLang, langSwitchHandler } = useContext(LangContext);

    const handleLangChange = (e: ChangeEvent<{ value: unknown }>) => {
        langSwitchHandler(e.target.value as string);
        document.title = e.target.value === 'fa' ? 'سالار فرهمند | مدرس برنامه نویسی' : 'Salar Farahmand | Online Instructor';
    }

    return (      
        <select
            value={currentLang}
            onChange={handleLangChange}
        >
            <option value="" disabled>
                Language
            </option>                
            {Object.entries(languageOptions).map(([id, name]) => (
                <option key={id} value={id}>{name}</option>
            ))}                
        </select>
    )
}

export default LangSwitcher
