import { Button, Grid, Typography, makeStyles, Fade, Slide, Grow, Theme, Box } from '@material-ui/core';
// import { useTheme } from '@material-ui/core/styles';
import React, { useState, useContext, useEffect } from 'react'
import Header from '../components/Header'
import Course from '../components/Course';
import InstructorInfo from '../components/InstructorInfo';
import { courses as courses_en } from '../data/languages/en';
import { courses as courses_fa } from '../data/languages/fa';
import { CourseInstance } from '../types/course';
import { LangContext } from '../context/langContext';

const useStyles = makeStyles((theme: Theme) => ({
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    header: {
        fontSize: '3vw',
        // marginTop: '20px',
        // marginBottom: '20px',
    },    
	button: {
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		// background: 'linear-gradient(45deg, #bdc3c7 30%, #2c3e50 90%)', //  50 Shades of Grey
		// background: 'linear-gradient(45deg, #de6262 30%, #ffb88c 90%)', // A Lost Memory
		// background: 'linear-gradient(45deg, #eb3349 30%, #f45c43 90%)', // Cherry
		background: 'linear-gradient(45deg, #141e30 30%, #243b55 90%)', // Royal
		width: '100%',
        height: '15vw',
		'&:hover': {
			borderColor: 'black',
			background: 'linear-gradient(45deg, #4ca1af 30%, #c4e0e5 90%)', // Decent
		},
		color: 'white',
        animation: 'mymove 5s infinite', // not worked!!!
	},
    '@keyframes mymove': {
        from: {left: '0px'},
        to: {left: '400px'}
    }
    // '@media (min-width: 960px)': {
    //     root: {
    //       display: 'flex',
    //     },
    // },
}));

const Courses = () => {
    // const theme = useTheme(); // useTheme hook
    const classes = useStyles();

    const [ courses, setCourses ] = useState<CourseInstance[]>(courses_fa);
    const { currentLang } = useContext(LangContext);
    


    const [ filteredCourses, setFilteredCourses ] = useState<CourseInstance[]>([]);
    const [ stepOne, setStepOne ] = useState(true); // by default
    const [ stepTwo, setStepTwo ] = useState(false); // questions
    const [ stepThree, setStepThree ] = useState(false); // course[0]
    const [ stepFour, setStepFour ] = useState(false); // courses[1], courses[2]
    const [ stepFive, setStepFive ] = useState(false); // courses[3], courses[4]
    const [ stepSix, setStepSix ] = useState(false); // all courses
    const [openInstructorCard, setOpenInstructorCard] = React.useState(false);


    useEffect(() => {
        setCourses(currentLang === 'fa' ? courses_fa : courses_en);
        // console.log('useEffect called!', currentLang);        
        resetFiltersHandler(); // **IMPORTANT** NOTE: When we change the courses state, the filteredCourses hold its previous state (whether its english or persian); because it doesn't know about the value changes in the dom. So the simple solution could be refresh the filteredCourses state by rseting all filters!
    }, [currentLang])



	const handleClickOpen = () => {
		setOpenInstructorCard(true);
	};
	const handleClose = () => {
		setOpenInstructorCard(false);
	};

    const stepOneHandler = () => {
        setStepOne(false);
        setStepTwo(true);
        setFilteredCourses([]);
    };

    const stepTwoOneHandler = () => {
        setStepTwo(false);
        setStepSix(true)
        setFilteredCourses(courses);
    };

    const stepTwoTwoHandler = () => {
        setStepTwo(false);
        setStepThree(true);
        setFilteredCourses([courses[0]]);
    };

    const stepThreeHandler = () => {
        setStepThree(false);
        setStepFour(true);
        setFilteredCourses([courses[1], courses[2]]);
    };

    const stepFourHandler = () => {
        setStepFour(false);
        setStepFive(true);
        setFilteredCourses([courses[3], courses[4]]);
    };

    const stepFiveHandler = () => {
        setStepFive(false);
        setStepSix(true)
        setFilteredCourses(courses);
    };

    const resetFiltersHandler = () => {
        setStepOne(true);
        setStepTwo(false);
        setStepThree(false);
        setStepFour(false);
        setStepFive(false);
        setStepSix(false);
    };

    return (
        <>
            <Header title_en={'Online Courses'} title_fa={'دوره های آنلاین'} />
            <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: '30px', marginBottom: '20px' }}>
                <Box className={classes.header} mx={1} p={1}>
                    { filteredCourses.length > 0 ? (
                        currentLang === 'fa' ? 'لیست دوره های آموزشی آنلاین' : 'Online Courses List'
                    ): '' 
                    }
                </Box>                
            </Typography>           
            <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
                {/* When press the button show thw course with transition and next button. */}
                {/* STEP#1 */}
                {stepOne ? (
                    //  NOTE: Transition components must have exactly one child.
                    <Fade in={stepOne} style={{ transformOrigin: '0 0 0' }} {...(stepOne ? { timeout: 1000 } : {})} > 
                        <Box my={1} p={3}>
                            <Button onClick={stepOneHandler} className={classes.button} variant="outlined" size='large' color="primary">
                                <Typography variant='h3'>
                                    <Box className={classes.header}>
                                        { currentLang === 'fa' ? ' میخوام برنامه نویسی و توسعه وب یاد بگیرم' : 'I want to learn Programming and Web Development'  }
                                    </Box>
                                </Typography>   
                            </Button>                               
                        </Box>
                    </Fade>
                ): ''}
                {/* STEP#2 */}
                {stepTwo ? (
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                        <Slide direction="up" in={stepTwo} mountOnEnter unmountOnExit {...(stepTwo ? { timeout: 2000 } : {})}>
                            <Grid item xs>
                                {/* STEP#2/1 */}
                                <Box my={6} p={1}>
                                    <Button onClick={stepTwoOneHandler}  className={classes.button} variant="outlined" size='large' color="primary">
                                        <Typography variant='h3'>
                                            <Box className={classes.header}>
                                                { currentLang === 'fa' ? 'میدونم از کجا شروع کنم! همه دوره هارو نشونم بده' : 'I know where to start, show me all courses!'  }
                                            </Box>
                                        </Typography>                                           
                                    </Button>    
                                </Box>
                            </Grid>
                        </Slide>
                        <Slide direction="up" in={stepTwo} mountOnEnter unmountOnExit {...(stepTwo ? { timeout: 2000 } : {})}>
                            <Grid item xs>
                                {/* STEP#2/2 */}
                                <Box my={6} p={1}>
                                    <Button onClick={stepTwoTwoHandler}  className={classes.button} variant="outlined" size='large' color="primary">
                                        <Typography variant='h3'>
                                            <Box className={classes.header}>
                                                { currentLang === 'fa' ? ' تازه کار هستم و میخوام مقدمات رو یاد بگیرم' : 'I am a beginner and want to learn the fundamentals.'  }
                                            </Box>
                                        </Typography>                                           
                                    </Button>    
                                </Box>
                            </Grid>
                        </Slide>
                    </Grid>
                ): ''}
                {/* STEP#3 */}
                {stepThree ? (                     
                    <>
                    <Slide direction="up" in={stepThree} mountOnEnter unmountOnExit {...(stepThree ? { timeout: 1000 } : {})}>                        
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={6}>
                            {filteredCourses.map((course) => (
                                <Course key={course.title} course={course} onClickReserve={handleClickOpen} />
                            ))}
                        </Grid>
                    </Slide>                                 
                    <Slide direction="up" in={stepThree} mountOnEnter unmountOnExit {...(stepThree ? { timeout: 3000 } : {})}>                        
                        <Box my={6} p={1}>
                            <Button onClick={stepThreeHandler}  className={classes.button} variant="outlined" size='large' color="primary">
                                <Typography variant='h3'>
                                    <Box className={classes.header}>
                                        { currentLang === 'fa' ? 'علاقه داری بیشتر یاد بگیری؟' : 'Do you want to learn more?'  }
                                    </Box>
                                </Typography>                                   
                            </Button> 
                        </Box>
                    </Slide>                                 
                    </>
                ): ''}
                {/* STEP#4 */}
                {stepFour ? (
                    <>
                    <Slide direction="up" in={stepFour} mountOnEnter unmountOnExit {...(stepFour ? { timeout: 1000 } : {})}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={6}>
                            {filteredCourses.map((course) => (
                                <Course key={course.title} course={course} onClickReserve={handleClickOpen} />
                            ))}
                        </Grid>
                    </Slide>
                    <Slide direction="up" in={stepFour} mountOnEnter unmountOnExit {...(stepFour ? { timeout: 3000 } : {})}>
                        <Box my={6} p={1}>
                            <Button onClick={stepFourHandler}  className={classes.button} variant="outlined" size='large' color="primary">
                                <Typography variant='h3'>
                                    <Box className={classes.header}>
                                        { currentLang === 'fa' ? 'میخوای بیشتر درباره جاوااسکریپت یاد بگیری؟' : 'Do you want to learn more about JavaScript?'  }
                                    </Box>
                                </Typography>                                   
                            </Button>                
                        </Box>
                    </Slide>
                    </>
                ): ''}
                {/* STEP#5 */}
                {stepFive ? (
                    <>
                    <Slide direction="up" in={stepFive} mountOnEnter unmountOnExit {...(stepFive ? { timeout: 1000 } : {})}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={6}>
                            {filteredCourses.map((course) => (
                                <Course key={course.title} course={course} onClickReserve={handleClickOpen} />
                            ))}
                        </Grid>
                    </Slide>
                    <Slide direction="up" in={stepFive} mountOnEnter unmountOnExit {...(stepFive ? { timeout: 3000 } : {})}>
                        <Box my={6} p={1}>
                            <Button onClick={stepFiveHandler}  className={classes.button} variant="outlined" size='large' color="primary">
                                <Typography variant='h3'>
                                    <Box className={classes.header}>
                                        { currentLang === 'fa' ? 'همه دوره ها رو نمایش بده' : 'Show me all courses'  }                                        
                                    </Box>
                                </Typography>                                   
                            </Button>                
                        </Box>
                    </Slide>
                    </>
                ): ''}                
                {/* STEP#6 */}
                {stepSix ? (
                    <>
                    <Grow in={stepSix} {...(stepSix ? { timeout: 1000 } : {})}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={6}>
                            {filteredCourses.map((course) => (
                                <Course key={course.title} course={course} onClickReserve={handleClickOpen} />
                            ))}
                        </Grid>          
                    </Grow>
                    <Grow in={stepSix} {...(stepSix ? { timeout: 4000 } : {})}>
                        <Box my={8} p={1}>
                            <Button onClick={resetFiltersHandler} color="secondary">{ currentLang === 'fa' ? 'ریست همه فیلتر ها' : 'Reset All Filters'  } </Button>    
                        </Box>
                    </Grow>
                    </>
                ): ''}       

                <InstructorInfo onOpen={openInstructorCard} onClose={handleClose} />         
            </Grid>         
        </>
    )
}

export default Courses
