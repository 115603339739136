import { Container, Link, makeStyles, Typography, Theme, Box } from '@material-ui/core';
import PropTypes from 'prop-types'
import React from 'react'
import LangSwitcher from './LangSwitcher';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://websateam.ir/">
            websateam
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
  
const useStyles = makeStyles((theme: Theme) => ({
    footer: {
      backgroundColor: '#f1ebeb',    
    //   padding: theme.spacing(6, 0),
      position: 'fixed',
      width: '100%',
      bottom: 0,
    //   height: '10%',
      opacity: 0.9,
    },   
}));

interface FooterProps {
    title: string;
    description: string;
}

const Footer = (props: FooterProps) => {
    const classes = useStyles();
    const { description, title } = props;
    

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    {title}
                </Typography>

                <Box display='flex' justifyContent='flex-start' alignItems='center' m={1} px={0}>
                    <Box flexGrow={1}>
                        <LangSwitcher />
                    </Box>
                    <Box flexGrow={11}>
                        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                            {description}
                        </Typography>
                        <Copyright />
                    </Box>
                </Box>
            </Container>
        </footer>
    )
}

Footer.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};

export default Footer
