import React, { useContext } from 'react';
import { Container } from '@material-ui/core';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Courses';
import { LangContext } from './context/langContext';
import NotFound from './pages/NotFound';


function App() {

    const { currentLang } = useContext(LangContext);

	return (
		<Router>
			<Container maxWidth='md'>
				<main dir={ currentLang === 'fa' ? 'rtl' : 'ltr' }>
					<Switch>
						{/* PUBLIC-AREA */}
						<Route path='/courses' component={Courses} />
						<Route path='/' component={Home} exact />
						<Route path='*' component={NotFound} /> {/* NOTE: To prevent rendering multiple path matches, wrap all with <Switch> component. */}
					</Switch>
				</main>
			</Container>
			<Footer title={''} description={''} />
		</Router>
	);
}

export default App;
