import { CourseInstance } from "../../types/course";

export const courses: CourseInstance[] = [
    {
        image: '/images/course-0.png',
        title: 'طراحی و توسعه وب با HTML/CSS/JS',
        description: `
            در انتهای این دوره آموزشی
            با مقدمات برنامه نویسی و توسعه تحت وب آشنایی پیدا کرده
            و آمادگی پایه لازم جهت فراگیری مباحث حوزه ی سمت سرور و سمت کاربر را به دست می آورید.
        `,
        level: 'مقدماتی', 
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'فارسی', 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'ندارد',
        }],        
    },
    {
        image: '/images/course-1.png',
        title: 'مفاهیم اساسی JavaScript',
        description: `           
            در انتهای این دوره آموزشی
            توانایی لازم جهت کار با مفاهیم مقدماتی و اساسی زبان برنامه نویسی جاوااسکریپت          
            از جمله عملگرها, عبارات شرطی, مفاهیم شی و آرایه و بسیاری موارد دیگر
            را بدست می آورید.
        `,
        level: 'مقدماتی', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'فارسی', 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'HTML/CSS/JS طراحی و توسعه وب با',
        }],        
    },
    {
        image: '/images/course-2.png',
        title: 'مفاهیم اساسی Python',
        description: `          
            در انتهای این دوره آموزشی
            توانایی کار با مفاهیم اساسی زبان برنامه نویسی پایتون
            از جمله عملگر ها, عبارات شرطی, مفاهیم شی و دیکشنری و آرایه و
            بسیاری موارد دیگر
            را به دست می آورید.
        `,
        level: 'مقدماتی', 
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'فارسی', 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'ندارد',
        }],        
    },
    {
        image: '/images/course-3.png',
        title: 'مفاهیم اساسی React.js',
        description: `
            در انتهای این دوره آموزشی
            توانایی لازم جهت کار با مفاهیم اصولی و اساسی کتابخانه محبوب React.js 
            از جمله معماری, مسیریابی
            و بسیاری موارد دیگر 
            را به دست می آورید.
        `,
        level: 'متوسط', 
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'فارسی', 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'مفاهیم اساسی JavaScript',
        }],        
    },
    {
        image: '/images/course-4.jpeg',
        title: 'مفاهیم اساسی Node.js',
        description: `
            در انتهای این دوره آموزشی 
            توانایی لازم جهت کار با محیط زمان اجرا Node.js
            و معماری آن و مسیر یابی
            و بسیاری موارد دیگر
            را خواهید آموخت.
        `,
        level: 'متوسط', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'فارسی', 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'مفاهیم اساسی JavaScript',
        }],        
    },
];

export const instructor = {
    name: 'سالار فرهمند',
    position: 'مهندس نر افزار و مدرس حرفه ای',
    bio: `
         بیش از پنج سال تجربه در محیط های نرم افزاری عملیاتی و تجربه کار با زبان های برنامه نویسی
         سی شارپ و
         پایتون و
         پی اچ پی و
         جاوا اسکریپت
         دارم.
        من بعنوان شغل پاره وقت تدریس میکنم.
         در واقع پیچیدگی های مسائل مختلف را پیدا کرده و تلاش میکنم تا فهمیدن آنها را برای بقیه آسان کنم.
        من برای بسیاری از دانشجویان در داخل و خارج از محیط دانشگاه تدریس کرده ام و همیشه بازخورد های عالی دریافت کردم.
        بنابراین
         بسیار علاقه مند به یادگیری مفاهیم جدید در حوزه ی مهندسی نرم افزار هستم و
        بسیار بیشتر علاقه مندم تا دانش خودم رو با شما به اشتراک بگذارم تا شغل و یا هدف دلخواه خودتون رو بدست بیاورید.
        من بسیار هیجان زده هستم تا به شما مفاهیم جدید رو به عنوان مدرستون آموزش بدهم.
        فقط کافیه همین امروز شروع کنی. فردا دیره ...!

        جهت اطلاع از دوره های آموزشی جدید لطفا ما را در اینستاگرام دنبال نمایید
    `,
    contactDescription: '.جهت رزرو دوره های آموزشی بنده و یا سفارش پروژه, لطفا به شماره واتش اپ زیر پیام ارسال نمایید',
    contactNumber: '+989210285583'
}