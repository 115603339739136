import React, { useContext, useEffect } from 'react';
import {
	Card,
	CardContent,
	CardMedia,
	Grid,
	Hidden,
	makeStyles,
	Typography,
	Button,
	Dialog,
	AppBar,
	IconButton,
	Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SmartphoneRoundedIcon from '@material-ui/icons/SmartphoneRounded';
import Instagram from '@material-ui/icons/Instagram';
import { instructor as instructor_en } from '../data/languages/en';
import { instructor as instructor_fa } from '../data/languages/fa';
import { LangContext } from '../context/langContext';
import { useState } from 'react';
import { Instructor } from '../types/instructor';

const useStyles = makeStyles({
	appBar: {
		position: 'relative',
		background: 'linear-gradient(45deg, #141e30 30%, #243b55 90%)',
	},
	card: {
		display: 'flex',
	},
	cardDetails: {
		flex: 1,
	},
	cardMedia: {
		width: 250,
	},
});

type InstructorInfoProps = {
	onOpen: boolean;
	onClose: () => void;
};

const InstructorInfo = ({ onOpen, onClose }: InstructorInfoProps) => {
	const classes = useStyles();

	const [instructor, setInstructor] = useState<Instructor>(instructor_fa);
	const { currentLang } = useContext(LangContext);

	useEffect(() => {
		setInstructor(currentLang === 'en' ? instructor_en : instructor_fa);
	}, [currentLang]);

	return (
		<div>
			<Dialog
				fullScreen
				open={onOpen}
				onClose={onClose}
				aria-labelledby="responsive-dialog-title"
			>
				<AppBar className={classes.appBar}>
					{/* <Toolbar> */}
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						m={1}
						px={0}
					>
						<Box>
							<IconButton
								edge="start"
								color="inherit"
								onClick={onClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Box>
						<Box>
							<Button
								autoFocus
								onClick={onClose}
								color="primary"
								style={{ color: 'white' }}
							>
								{currentLang === 'fa' ? 'باشه' : 'OK'}
							</Button>
						</Box>
					</Box>
					{/* </Toolbar> */}
				</AppBar>

				<Grid item xs={12} md={12}>
					<Card className={classes.card}>
						<Hidden xsDown>
							<CardMedia
								className={classes.cardMedia}
								image="/images/me-0.jpg"
								title="Salar Farahmand"
							/>
						</Hidden>
						<Grid container direction="column" className={classes.cardDetails}>
							<CardContent>
								<Typography component="h2" variant="h5">
									{instructor.name}
								</Typography>
								<Typography variant="subtitle1" color="textSecondary">
									{instructor.position}
								</Typography>
								<Typography variant="subtitle1" paragraph>
									{instructor.bio}
								</Typography>
								<Typography variant="subtitle1" paragraph>
									{instructor.contactDescription}
								</Typography>
								<Grid item xs={12} md={9}>
									<SmartphoneRoundedIcon /> {instructor.contactNumber}
								</Grid>
								<Grid item xs={12} md={9}>
									<Instagram /> @websateam
								</Grid>
							</CardContent>
						</Grid>
					</Card>
				</Grid>
			</Dialog>
		</div>
	);
};

export default InstructorInfo;
