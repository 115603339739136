import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom'
import { LangContext } from '../context/langContext';

const NotFound = () => {
    const { pathname } = useLocation();

    const { currentLang } = useContext(LangContext);

    return (
        <>
            <h3>
                { currentLang === 'fa' ? 'نتیجه ای یافت نشد برای' : 'No match for'  } <code>{pathname}</code> 
            </h3>
            <strong>
                <Link to='/'>
                    { currentLang === 'fa' ? 'بازگشت' : 'Back'  } 
                </Link>
            </strong>        
        </>
    )
}

export default NotFound
