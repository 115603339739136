import React from 'react';

export const languageOptions = {
    fa: 'فارسی',
    en: 'English',
};


export const LangContext = React.createContext({
	currentLang: '',
	langSwitchHandler: (lang: string) => {},
});

const LangContextProvider = ({ children }: any) => { // NOTE: Function components start with uppercase letter (*).
	const [currentLang, setCurrentLang] = React.useState(
		window.localStorage.getItem('lang') == null
			? 'fa'
			: window.localStorage.getItem('lang')!
	);
    // const dictionary = dictionaryList[currentLang]; // xx not valid
	const langSwitchHandler = (lang: string) => {
		setCurrentLang(lang);
		window.localStorage.setItem('lang', lang);
		// document.body.className = document.body.className.replace(/lang-\w+/, lang);
	};

	return (
		<LangContext.Provider value={{ currentLang, langSwitchHandler }}>
			{children}
		</LangContext.Provider>
	);
};

export default LangContextProvider;
