import { CourseInstance } from "../../types/course";

export const courses: CourseInstance[] = [
    {
        image: '/images/course-0.png',
        title: 'Web Design and Development Fundamentals using HTML/CSS/JS',
        description: `
            In this course we learn:
             HTML5 and CSS3 basics and responsive concepts,
             twitter bootstrap framework,
             javascript fundamentals.
        `,
        level: 'Beginner', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'Persian', // FA | EN
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'None',
        }],        
    },
    {
        image: '/images/course-1.png',
        title: 'JavaScript Essential Concepts',
        description: `
            In this course we learn:
                familiarity with JavaScript programming language,
                familiarity with operators,
                familiarity with conditional statements,
                familiarity with objects and arrays,
                and much more.
        `,
        level: 'Beginner', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'Persian', // FA | EN
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'Web Design and Development Fundamentals using HTML/CSS/JS',
        }],        
    },
    {
        image: '/images/course-2.png',
        title: 'Python Essential concepts',
        description: `
            In this course we learn:
                familiarity with Python programming language,
                familiarity with operators,
                familiarity with conditional statements,
                familiarity with objects and arrays,
                and much more.        
        `,
        level: 'Beginner', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'Persian', // FA | EN
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'None',
        }],        
    },
    {
        image: '/images/course-3.png',
        title: 'React.js Essential Concepts',
        description: `
            In this course we learn:
                familiarity with React.js Library, 
                familiarity with React concepts and general architecture, 
                familiarity with routing, 
                and much more.           
        `,
        level: 'Intermediate', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'Persian', // FA | EN 
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'JavaScript Essential Concepts',
        }],        
    },
    {
        image: '/images/course-4.jpeg',
        title: 'Node.js Essential Concepts',
        description: `
            In this course we learn:
                familiarity with Node.js runtime environment, 
                familiarity with Node.js concepts and general architecture, 
                familiarity with routing, 
                and much more. 
        `,
        level: 'Intermediate', // Beginner | Advanced | All Levels
        totalHours: '22:30',
        lectureHourse: '01:30', // 1h30m
        lectures: 15,
        rateAverage: 5,
        language: 'Persian', // FA | EN
        features: {
            assignments: true,
            online: true,
            twoLectureFree: true,
        },
        prerequisite: [{
            title: 'JavaScript Essential Concepts',
        }],        
    },
];

export const instructor = {
    name: 'Salar Farahmand',
    position: 'Professional Software Engineer and Instructor',
    bio: `
        I have more than 5 years experience working in practical software environments.
        I'm a professional software developer and have experience with C#, Python, PHP and JavaScript programming languages.        
        I work as private instructor as part-time job. I discover the complexity of the problems and try to make them easy to understanding for others.
        I taught many students inside and outside the university and always received great feedbacks from my students.
        So I love learning new concepts in the field of software engineering and much more to sharing my knowledge with you to get your desired job or goal.
        I'm so excited to teach you new concepts as your instructor. 
        You just need to start it today, tomorrow is late!
        
        Please follow me on the instagram @websateam to inform about new courses.
    `,
    contactDescription: 'To reserve my course(s) or order a project, please send a message to the below whatsApp number:',
    contactNumber: '+989210285583'
}