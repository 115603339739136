import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { LangContext } from '../context/langContext';
import InstructorInfo from './InstructorInfo';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		backgroundColor: '#fdfdfd',
		height: '10%',
		opacity: 0.9,
		boxShadow: '0 10px #ecf1f080',
	},
	toolbarTitle: {
		flex: 1,
		fontSize: '0.75rem',
	},
	toolbarSecondary: {
		justifyContent: 'space-between',
		overflowX: 'auto',
	},
	toolbarLink: {
		padding: theme.spacing(1),
		flexShrink: 0,
	},
	button: {
		color: 'white',
		margin: theme.spacing(0),
		background: 'linear-gradient(45deg, #141e30 30%, #243b55 90%)',
	},
}));

interface HeaderProps {
	title_en: string;
	title_fa: string;
}

const Header = (props: HeaderProps) => {
	const classes = useStyles();
	const { title_en, title_fa } = props;

	const { currentLang } = useContext(LangContext);

	const [openInstructorCard, setOpenInstructorCard] = React.useState(false);

	const handleClose = () => {
		setOpenInstructorCard(false);
	};

	return (
		<React.Fragment>
			<Toolbar className={classes.toolbar}>
				<Link to="/courses" style={{ textDecoration: 'none', fontSize: '0.75rem' }}>
					{currentLang === 'fa' ? 'دوره های آموزشی' : 'Courses'}
				</Link>
				<Typography
					component="h2"
					variant="h5"
					color="inherit"
					align="center"
					noWrap
					className={classes.toolbarTitle}
				>
					<Link to="/" style={{ textDecoration: 'none' }}>
						{currentLang === 'fa' ? title_fa : title_en}
					</Link>
				</Typography>
				<Button
					variant="contained"
					size="small"
					className={classes.button}
					onClick={() => setOpenInstructorCard((prevState) => !prevState)}
				>
					<Typography
						style={{ textDecoration: 'none', fontSize: '0.75rem' }}
						component="h2"
						variant="h5"
						color="inherit"
						align="center"
						noWrap
					>
						{currentLang === 'fa' ? 'سفارش پروژه' : 'Order Project'}
					</Typography>
				</Button>
			</Toolbar>
			<InstructorInfo onOpen={openInstructorCard} onClose={handleClose} />
		</React.Fragment>
	);
};

Header.propTypes = {
	sections: PropTypes.array,
	title: PropTypes.string,
};

export default Header;
