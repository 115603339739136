import React, { useContext } from 'react';
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	Hidden,
	makeStyles,
	Typography,
} from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { LangContext } from '../context/langContext';

const useStyles = makeStyles({
	card: {
		display: 'flex',
		boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 
                  0px 1px 1px 0px rgb(0 0 0 / 14%), 
                  0px 1px 3px 0px rgb(0 0 0 / 12%),
                  0px 2px 1px -1px rgb(0 0 0 / 20%)`,
	},
	cardDetails: {
		flex: 1,
	},
	cardMedia: {
		width: 260,
		height: 225,
		// height: '20vw',
	},
	chip: {
		margin: '0 1px',
	},
	skeleton: {
		flexGrow: 1,
		padding: '30px',
	},
});

interface CourseProps {
	course: {
		image: string;
		title: string;
		description: string;
		level: string; // Beginner | Advanced | All Levels
		totalHours: string;
		lectureHourse: string; // 1h30m
		lectures: number;
		rateAverage: number;
		language: string; // FA | EN
		features: {
			assignments: boolean;
			online: boolean;
			twoLectureFree: boolean;
		};
		prerequisite: {
			title: string;
		}[];
	};
	onClickReserve: () => void;
}

const Course = ({ course, onClickReserve }: CourseProps) => {
	const classes = useStyles();

    const { currentLang } = useContext(LangContext);

	const [fakeDelay, setFakeDelay] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setFakeDelay(false);
		}, 3000);
	});

	return (
		<Grid item xs={12} md={9}>
			<CardActionArea component='a' href='#'>
				<Card className={classes.card}>
					{fakeDelay ? (
						<Grid
							className={classes.skeleton}
							container
							justify-content='space-between'
							spacing={2}
						>
							<Grid item xs>
								<Skeleton variant='rect' width={210} height={118} />
							</Grid>
							<Grid item xs={7}>
								<Skeleton />
								<Skeleton animation={false} />
								<Skeleton animation='wave' />
							</Grid>
						</Grid>
					) : (
						<>
							<Hidden xsDown>
								<CardMedia
									className={classes.cardMedia}
									image={course.image}
									title={course.title}
								/>
							</Hidden>
							<div className={classes.cardDetails}>
								<CardContent>
									<Box my={2}>
										<Typography component='h2' variant='h5'>
											{course.title}
										</Typography>
									</Box>
									<Grid
										container
										direction='row'
										justifyContent='space-between'
										alignItems='center'
									>
										<Grid item>
											<Chip
												className={classes.chip}
												size='small'
												label={course.level}
											/>
										</Grid>
										<Grid item>
											<Chip
												className={classes.chip}
												size='small'
												icon={<AccessTime />}
												label={course.totalHours}
											/>
										</Grid>
										<Grid item>
											{course.features.assignments ? (
												<Chip
													className={classes.chip}
													size='small'
													icon={<MenuBookRoundedIcon />}
													label={currentLang === 'fa' ? 'تمرینات' : 'Exercises '}
												/>
											) : (
												''
											)}
										</Grid>
										<Grid item>
											{course.features.online ? (
												<Chip
													className={classes.chip}
													size='small'
													icon={<LanguageRoundedIcon />}
													label={currentLang === 'fa' ? 'آنلاین' : 'Online '}
												/>
											) : (
												''
											)}
										</Grid>
									</Grid>
									<Box mt={2}>
										<Typography variant='subtitle1' color='textSecondary'>
											{/* {course.date} */}
										</Typography>
										<Typography variant='subtitle1' paragraph>
											{course.description}
										</Typography>
										<Typography variant='subtitle1' paragraph>
											{currentLang === 'fa' ? ' هر جلسه به مدت ' : 'Each letcture includes '}
											{course.lectureHourse}
											{currentLang === 'fa' ? '  می باشد. ' : ' hours.'}
										</Typography>
										<Typography variant='subtitle1' paragraph>											
											{currentLang === 'fa' ? 'پیش نیاز:' : 'Prerequisites:'} {course.prerequisite[0].title}
										</Typography>
										<Typography variant='subtitle1' color='primary'>
											<Button color='primary' onClick={onClickReserve}>
												{currentLang === 'fa' ? 'رزرو' : 'Reserve'}
											</Button>
										</Typography>
									</Box>
								</CardContent>
							</div>
						</>
					)}
				</Card>
			</CardActionArea>
		</Grid>
	);
};

export default Course;
