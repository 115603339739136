import React, { useContext } from 'react';
import {
	Box,
	Button,
	Slide,
	Fade,
	Typography,
	Theme,
	makeStyles,
	Grid,
} from '@material-ui/core';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { LangContext } from '../context/langContext';
import InstructorInfo from '../components/InstructorInfo';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		marginTop: '30px',
		// height: '2vw'
	},
	header: {
		textAlign: 'center',
		fontSize: '1.25rem',
		marginTop: '20px',
		marginBottom: '20px',
	},
	button: {
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		// background: 'linear-gradient(45deg, #bdc3c7 30%, #2c3e50 90%)', //  50 Shades of Grey
		// background: 'linear-gradient(45deg, #de6262 30%, #ffb88c 90%)', // A Lost Memory
		// background: 'linear-gradient(45deg, #eb3349 30%, #f45c43 90%)', // Cherry
		background: 'linear-gradient(45deg, #141e30 30%, #243b55 90%)', // Royal
		width: '100%',
		height: '20vw',
		'&:hover': {
			borderColor: 'black',
			background: 'linear-gradient(45deg, #4ca1af 30%, #c4e0e5 90%)', // Decent
		},
		color: 'white',
	},
}));

const Home = () => {
	const classes = useStyles();

	const { currentLang } = useContext(LangContext);

	const [openInstructorCard, setOpenInstructorCard] = React.useState(false);

	const handleClose = () => {
		setOpenInstructorCard(false);
	};

	return (
		<>
			<Header title_en={'Websa Academy'} title_fa={'آکادمی وبسا'} />
			<Grid>
				{' '}
				{/* NOTE: remove container property make buttons wider. */}
				<Grid item>
					<Fade in={true} {...(true ? { timeout: 7000 } : {})}>
						<Typography variant='h3'>
							<Box className={classes.header}>
								{currentLang === 'fa'
									? 'به آکادمی برنامه نویسی وبسا خوش آمدید'
									: 'Welcome to Websa Programming Academy'}
							</Box>
						</Typography>
					</Fade>
				</Grid>
				<Grid item>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<Slide
							direction='down'
							in={true}
							mountOnEnter
							unmountOnExit
							{...(true ? { timeout: 1000 } : {})}
						>
							<Grid item xs>
								<Link to='/courses' style={{ textDecoration: 'none' }}>
									<Box mx={2} p={1}>
										<Button
											className={classes.button}
											variant='outlined'
											size='large'
											color='primary'
										>
											<Typography variant='h3'>
												<Box className={classes.header}>
													{currentLang === 'fa' ? 'دوره های آموزشی' : 'Courses'}
												</Box>
											</Typography>
										</Button>
									</Box>
								</Link>
							</Grid>
						</Slide>
						<Slide
							direction='up'
							in={true}
							mountOnEnter
							unmountOnExit
							{...(true ? { timeout: 1000 } : {})}
						>
							<Grid item xs>
								{/* <Link to='/order/project' style={{ textDecoration: 'none' }}> */}
									<Box mx={2} p={1}>
										<Button
											className={classes.button}
											variant='outlined'
											size='large'
											color='primary'
											onClick={() => setOpenInstructorCard(prevState => !prevState)}
										>
											<Typography variant='h3'>
												<Box className={classes.header}>
													{currentLang === 'fa'
														? 'سفارش پروژه'
														: 'Order Project'}
												</Box>
											</Typography>
										</Button>
									</Box>
								{/* </Link> */}
							</Grid>
						</Slide>
						<InstructorInfo onOpen={openInstructorCard} onClose={handleClose} />
					</Grid>
				</Grid>
				{/* <ProTip /> */}
			</Grid>
		</>
	);
};

export default Home;
